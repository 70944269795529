import { setDimension, TrackingDimensions } from '@ecp/utils/analytics/tracking';
import { datadogLog } from '@ecp/utils/logger';
import { Headers } from '@ecp/utils/network';
import { location } from '@ecp/utils/routing';

import { BINDABLE_BRIDGEOVER, INDICATIVE_BRIDGEOVER } from '@ecp/features/sales/shared/constants';
import { getUpdatedPageflowsFromConfig, PagePath } from '@ecp/features/sales/shared/routing';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { SalesRequestError, type SalesResponse } from '@ecp/features/sales/shared/utils/network';

import type { SapiTarget } from '../api';
import { setSapiTarget } from '../api';
import { jumpToPage } from '../global/thunks';
import { setInquiryOnOffersApiSuccess } from '../inquiry';
import {
  getLineOfBusiness,
  getLineOfBusinessUserSelection,
  getSelectedPaymentPlan,
} from '../inquiry/selectors';
import { updateAnswers, updateLobOrder } from '../inquiry/thunks';
import { makePreviousPagesValid } from '../nav/thunks';
import { wrapThunkActionWithErrHandler } from '../util/wrapThunkActionWithErrHandler';
import {
  setBlobRetrieveFailed,
  setOffersApiRecallFail,
  setOffersApiRecallSuccess,
} from './actions';
import { postBlobRetrieve } from './api';
import { prepareCheckoutPageState } from './expressPath';
import { handleBlobRetrieveError } from './handleBlobRetrieveError';
import { makeQuoteRecallNavigation } from './recallNav';
import { getOffersForSelectedLob } from './selectors';
import { handlePremiumPlanSelection } from './thunks';
import type { OffersRecallResponse } from './types';
import { isIndicativeQuoteRetrievalDenied, isOfferAutoSwitchRequote } from './util';

const updateIndicativeBridgeOverAnswer: () => ThunkAction<Promise<void>> =
  () => async (dispatch, getState) => {
    await dispatch(
      updateAnswers({
        answers: {
          [INDICATIVE_BRIDGEOVER]: true,
        },
      }),
    );
  };

const trackEmailRetrieveRecallEvent: (
  emailUUID: string,
  response: SalesResponse<OffersRecallResponse>,
  inquiryId: string,
  dalSessionId: string,
) => ThunkAction<Promise<void>> =
  (emailUUID, response, inquiryId, dalSessionId) => async (dispatch, getState) => {
    trackSapiAnalyticsEvent({
      element: 'choice.emailRetrieve.recall',
      event: 'captured',
      eventDetail: `emailUUID: ${emailUUID}, offersResponseCode: ${response.status}, offersResponseText: ${response.payload}, XTraceID: ${response.requestId}, inquiryID: ${inquiryId}, dalSessionId: ${dalSessionId}`,
    });
  };

const handleIndicativeOffer: (
  emailUUID: string,
  response: SalesResponse<OffersRecallResponse>,
  inquiryId: string,
  dalSessionId: string,
) => ThunkAction<Promise<void>> =
  (emailUUID, response, inquiryId, dalSessionId) => async (dispatch, getState) => {
    const searchParams = location.search;

    if (searchParams.source === 'bridge') {
      const lineOfBusiness = getLineOfBusiness(getState());
      await dispatch(updateLobOrder(lineOfBusiness));
      await dispatch(updateIndicativeBridgeOverAnswer());
    }

    const nextPage = PagePath.TRANSITION;
    if (searchParams.source !== 'bridge')
      await dispatch(trackEmailRetrieveRecallEvent(emailUUID, response, inquiryId, dalSessionId));
    dispatch(makePreviousPagesValid(nextPage));
    dispatch(jumpToPage({ page: nextPage }));
  };

const updateBindableAnswer: () => ThunkAction<Promise<void>> = () => async (dispatch, getState) => {
  await dispatch(
    updateAnswers({
      answers: {
        [BINDABLE_BRIDGEOVER]: true,
      },
    }),
  );
};

const handleNonIndicativeOffer: (
  emailUUID: string,
  response: SalesResponse<OffersRecallResponse>,
  inquiryId: string,
  dalSessionId: string,
  isExpressPathToCheckout: boolean | undefined,
) => ThunkAction<Promise<void>> =
  (emailUUID, response, inquiryId, dalSessionId, isExpressPathToCheckout) =>
  async (dispatch, getState) => {
    const searchParams = location.search;
    const nextPage = '/quote/quotes?loading';

    if (searchParams.source === 'bridge') await dispatch(updateBindableAnswer());

    // Get page flows from config for the line of business
    const lineOfBusiness = getLineOfBusiness(getState());
    await dispatch(
      getUpdatedPageflowsFromConfig({
        modifiedLOB: lineOfBusiness,
      }),
    );
    dispatch(setOffersApiRecallSuccess(response.payload));

    if (searchParams.source !== 'bridge') {
      await dispatch(trackEmailRetrieveRecallEvent(emailUUID, response, inquiryId, dalSessionId));
    }

    const state = getState();
    const offersForSelectedLob = getOffersForSelectedLob(state);
    const selectedLob = getLineOfBusinessUserSelection(state) || getLineOfBusiness(state);

    // Set the payment term if only full pay is available for bridge over scenario
    const paymentPlan = getSelectedPaymentPlan(state);
    if (!paymentPlan) {
      await dispatch(
        handlePremiumPlanSelection({
          offers: offersForSelectedLob,
          selectedLob: selectedLob,
        }),
      );
    }
    // Set page status all to valid, since they have to be valid to get an offer and then recall
    dispatch(makePreviousPagesValid(nextPage));

    // Express path navigation must be handled after bootstrapping
    if (!isExpressPathToCheckout) {
      await dispatch(
        makeQuoteRecallNavigation({
          nextPage,
          noOfferPage: '/quote/person',
          initialRecallRequest: true,
        }),
      );
    } else {
      await dispatch(prepareCheckoutPageState());
      dispatch(jumpToPage({ page: '/app/checkout' }));
    }
  };

const doRetrieveCallAndSaveToStore: (
  encryptedData: string,
  source: string,
) => ThunkAction<
  Promise<{
    inquiryId: string;
    dalSessionId: string;
    response: SalesResponse<OffersRecallResponse>;
  }>
> = (encryptedData, source) => async (dispatch, getState) => {
  const response = await postBlobRetrieve({ encryptedData, source });

  const hasSapiTarget = Headers.SAPI_TARGET in response.headers;
  if (!hasSapiTarget) {
    datadogLog({
      logType: 'error',
      message: 'sapi target not found in response headers for blobRetrieve',
      context: {
        logOrigin: 'libs/features/sales/shared/store/lib/src/offers/blobRetrieve.ts',
        functionOrigin: 'doRetrieveCallAndSaveToStore',
      },
    });

    throw new Error('sapi target not found in response headers for blobRetrieve');
  }
  const sapiTarget = response.headers[Headers.SAPI_TARGET] as SapiTarget;
  dispatch(setSapiTarget(sapiTarget));

  const dalSessionId = response.payload.dalSessionId;
  dispatch(
    setInquiryOnOffersApiSuccess({
      dalSessionId,
      inquiryId: response.payload.data.inquiryId,
      answers: response.payload.data.answers,
      questions: response.payload.data.questions,
    }),
  );
  const inquiryId = response.payload.data.inquiryId;
  if (response.payload.data.offerSetId !== undefined) {
    setDimension(TrackingDimensions.RETREIVE_FLAG, true);
  }

  return { inquiryId, dalSessionId, response };
};

export const blobRetrieve = wrapThunkActionWithErrHandler<
  {
    encryptedData: string;
    emailUUID: string;
    source: string;
    isExpressPathToCheckout?: boolean;
  },
  boolean
>(
  ({ encryptedData, emailUUID, source, isExpressPathToCheckout }) =>
    async (dispatch, getState) => {
      let response: SalesResponse<OffersRecallResponse> | undefined;
      let inquiryId = '';
      let dalSessionId = '';

      try {
        dispatch(setOffersApiRecallFail(''));
        const result = await dispatch(doRetrieveCallAndSaveToStore(encryptedData, source));
        response = result.response;
        inquiryId = result.inquiryId;
        dalSessionId = result.dalSessionId;
        const state = getState();
        const offersForSelectedLob = getOffersForSelectedLob(state);
        const selectedLob = getLineOfBusinessUserSelection(state) || getLineOfBusiness(state);

        await dispatch(
          handlePremiumPlanSelection({
            offers: offersForSelectedLob,
            selectedLob: selectedLob,
          }),
        );

        const isOfferAutoSwitch = isOfferAutoSwitchRequote(response.payload);
        if (isOfferAutoSwitch) {
          dispatch(setBlobRetrieveFailed(true));
          datadogLog({
            logType: 'warn',
            message:
              'AUTO_SWITCH_RE_QUOTE - Your auto quote has expired. Please start a new quote by  clicking the link below.',
            context: {
              logOrigin: 'libs/features/sales/shared/store/lib/src/offers/blobRetrieve.ts',
              functionOrigin: 'blobRetrieve',
            },
          });

          return true; // because sapi return 200, will treat it as success
        }

        const indicativeOfferExists = isIndicativeQuoteRetrievalDenied(response.payload);

        if (indicativeOfferExists) {
          await dispatch(handleIndicativeOffer(emailUUID, response, inquiryId, dalSessionId));
        } else {
          await dispatch(
            handleNonIndicativeOffer(
              emailUUID,
              response,
              inquiryId,
              dalSessionId,
              isExpressPathToCheckout,
            ),
          );
        }

        dispatch(setBlobRetrieveFailed(false));

        return true; // success
      } catch (error) {
        const e = error as Error | SalesRequestError;

        datadogLog({
          logType: 'error',
          message: `Error Recalling Quote - ${e?.message}`,
          context: {
            logOrigin: 'libs/features/sales/shared/store/lib/src/offers/blobRetrieve.ts',
            functionOrigin: 'blobRetrieve',
            ...(e instanceof SalesRequestError && { errorData: e.errorData }),
          },
          error: e,
        });

        await dispatch(
          handleBlobRetrieveError({
            error: e,
            emailUUID,
            inquiryId,
            dalSessionId,
          }),
        );

        return false; // failure
      }
    },
  'blobRetrieve',
);
