import { useCallback, useState } from 'react';

import { Grid } from '@mui/material';

import { isTruthy } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { GridItem, HelpBox } from '@ecp/components';
import { env } from '@ecp/env';
import { SaveAndExit } from '@ecp/features/sales/shared/components';
import { Link } from '@ecp/features/sales/shared/components';
import { PagePath } from '@ecp/features/sales/shared/routing';
import { getPrimaryInsuredStateCode } from '@ecp/features/sales/shared/store';
import { getCurrentPage } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { GraphicUIAgentAvatarImageUrl } from '@ecp/themes/base';

import { getIAgreeAcknowledgements } from '../../../../state/acknowledgements';
import { ProtectedPersonsDisclosureDialog } from '../../ProtectedPersonsDisclosureDialog';
import { useStyles } from './ImportantInformation.styles';
import { useMetadata } from './utils';

export const ImportantInformation: React.FC = () => {
  const { classes } = useStyles();
  const stateCode = useSelector(getPrimaryInsuredStateCode);

  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  // TODO: We can clean this up once SAPI has moved this key to disclosures or similar
  // That work is with ticket: SAPI-16564
  const iAgrees = useSelector(getIAgreeAcknowledgements);
  const hasProtectedPersonsContent =
    iAgrees.property?.some((iAgree) => iAgree.key === 'isProtectedPerson') || false;
  const currentPage = useSelector(getCurrentPage);
  // Save and Exit Experiment, Variant C: Add save and exit link and text to “Important Information” block at bottom of checkout page.
  // save and exit only render on checkout page, exclude the checkout retry page
  // Important Information will display only if the corresponding state has fraud warning
  const isVariantC = flagValues[FeatureFlags.SAVE_AND_EXIT] === 'VARIANT_C';
  const removeSaveExit = currentPage === PagePath.CHECKOUT_RETRY;

  const { fraudWarning } = useMetadata();

  const fraudStatements = env.static.isAgent ? undefined : fraudWarning;

  if (!fraudWarning && !hasProtectedPersonsContent) return null;

  const statements = [
    fraudStatements,
    hasProtectedPersonsContent && (
      <>
        Please review the following important information for {stateCode}:{' '}
        <Link component='button' onClick={handleOpen} data-testid='protectedPersonsLink'>
          Protected Persons
        </Link>
        <ProtectedPersonsDisclosureDialog
          open={open}
          onClose={handleClose}
          trackingName='ProtectedPersonsCloseButton'
          trackingLabel='ProtectedPersonsClose'
        />
      </>
    ),
    // SAVE and EXIT experiment
    // this save and exit link and text will only display,
    // when save&exit ff variant C is turned on and it's on the checkout page
    isVariantC && !removeSaveExit && (
      <>
        Need more time? You can <SaveAndExit isLink /> and return later to complete your purchase.
      </>
    ),
  ].filter(isTruthy);

  return (
    <GridItem xs={12} topSpacing='sm'>
      <HelpBox
        image={GraphicUIAgentAvatarImageUrl}
        data-testid='helpBoxIcon'
        content={
          <Grid>
            <strong>Important Information</strong>
            {statements.length === 1 ? (
              <p>{statements[0]}</p>
            ) : (
              <ul className={classes.list}>
                {statements.map((statement, index) => (
                  <li key={index}>{statement}</li>
                ))}
              </ul>
            )}
          </Grid>
        }
      />
    </GridItem>
  );
};
