import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'SaveAndExit' })((theme) => ({
  modalContainer: {
    maxWidth: 550,
  },
  linkButton: {
    fontSize: '14px',
  },
}));
