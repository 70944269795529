import { useState } from 'react';

import { Grid } from '@mui/material';

import { useEvent } from '@ecp/utils/react';

import { GridItem } from '@ecp/components';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import { getFullPersonInfo, usePniRef } from '@ecp/features/sales/shared/store';
import { startOverFlow } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

import { Button } from '../Button';
import { Dialog } from '../Dialog';
import { useStyles } from './SaveAndExit.styles';

interface Props {
  handleSave?: () => void;
  isLink?: boolean;
}

export const SaveAndExit: React.FC<Props> = ({ handleSave, isLink }) => {
  const { classes } = useStyles();
  const pniRef = usePniRef();
  const pni = useSelector((state: RootStore) => getFullPersonInfo(state, pniRef));
  const { email } = pni;
  const dispatch = useDispatch();

  const [displayDialog, setDisplayDialog] = useState(false);
  const [exitProcessing, setExitProcessing] = useState(false);

  const handleDialogShow = useEvent(() => {
    setDisplayDialog(true);
  });

  const navigateToRetrievePage = useNavigateToPage(PagePath.LANDING_RETRIEVE);

  const handleDialogCancel = useEvent(() => {
    setDisplayDialog(false);
  });

  const handleDialogClose = useEvent(() => {
    setDisplayDialog(false);
  });

  const handleDialogExit = useEvent(async () => {
    setExitProcessing(true);
    if (handleSave) {
      handleSave();
    }
    await dispatch(startOverFlow());
    await navigateToRetrievePage();
    setExitProcessing(false);
    setDisplayDialog(false);
  });

  return (
    <>
      {isLink ? (
        <Button className={classes.linkButton} variant='text' onClick={handleDialogShow}>
          save & exit
        </Button>
      ) : (
        <Button
          variant='iconText'
          onClick={handleDialogShow}
          trackingName='save_and_exit_link'
          trackingLabel='save_and_exit'
        >
          Save and exit
        </Button>
      )}
      <Dialog
        actionButtonLabel='Exit'
        textButtonLabel='Cancel'
        titleText='Your progress is saved'
        buttonPlacement='left'
        open={displayDialog}
        onClose={handleDialogClose}
        isProcessing={exitProcessing}
        disableActionButton={exitProcessing}
        actionButtonOnClick={handleDialogExit}
        textButtonOnClick={handleDialogCancel}
        trackingNameButton='save_and_exit_button'
        trackingLabelButton='exit'
        trackingNameCloseIcon='save_and_exit_x'
        trackingLabelCloseIcon='x'
        trackingNameText='save_and_exit_cancel'
        trackingLabelText='cancel'
      >
        <Grid container className={classes.modalContainer}>
          <GridItem>
            <p>
              You'll receive an email at {email} with a link to continue your quote. Please allow up
              to 24 hours for delivery.
            </p>
          </GridItem>
          <GridItem topSpacing='sm'>
            <p>
              It's now safe to 'Exit'. If you wish to continue after exiting, you may use the link
              in your email or 'Continue' on the following screen. For security, you'll be asked to
              enter some basic information before accessing your quote.
            </p>
          </GridItem>
        </Grid>
      </Dialog>
    </>
  );
};
